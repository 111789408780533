import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Dotdotdot from 'react-dotdotdot'
import insert from 'ramda/es/insert'

import { SwipeActionButton, MatchAvatar, SocialSection } from 'components'
import { logEvent } from 'services/helpers'
import ChatIcon from 'static/images/chatBlue.svg'
import ArrowIcon from 'static/images/arrowBlue.svg'
import PersonIcon from 'static/images/personBlue.svg'
import { Button } from 'components/Button'
import { ENTER_KEY_CODE, LEFT_ARROW_KEY_CODE, RIGHT_ARROW_KEY_CODE } from 'constants/common'
import i18n from 'services/i18n'

import c from './SavedCard.module.scss'

const selectCardAnim = (type) => type === 'skip' ? c.leftCardAnim : c.rightCardAnim

class SavedCard extends PureComponent {
  state = {
    animOut: false
  }

  onActionClick = () => {
    this.setState({ animOut: Math.random() + 1 })

    setTimeout(() => {
      const { onActionClick, logType } = this.props
      onActionClick()

      if (logType.includes('Skipped')) {
        logEvent('action.Saved.Like')
      } else if (logType.includes('Liked.OneWay')) {
        logEvent('action.Saved.OneWay.Skip')
      } else if (logType.includes('Liked.OneWay')) {
        logEvent('action.Saved.Mutual.Skip')
      }
    }, 100)
  }

  onRequestClick = (e) => {
    e.stopPropagation()
    this.props.onRequestClick()
    logEvent(`action.${this.props.logType}.Request`)
  }

  onStartChatClick = (e) => {
    const { logType, onStartChatClick } = this.props

    e.stopPropagation()
    onStartChatClick()
    logEvent(`action.${logType}.Chat`)
  }

  onKeyDown = (event) => {
    switch (event.keyCode) {
      case LEFT_ARROW_KEY_CODE:
        if (this.props.onActionClick && this.props.action === "skip") {
          this.props.onActionClick();
        }
        break;
      case RIGHT_ARROW_KEY_CODE:
        if (this.props.onActionClick && this.props.action === "like") {
          this.props.onActionClick();
        }
        break;
      case ENTER_KEY_CODE:
        if (event.target.tagName === "ARTICLE") {
          this.props.onItemClick()
        }
        break;
      default:
        break
    }
  }

  render() {
    const { animOut } = this.state
    const {
      action, onItemClick, logType,
      data: { avatar, match, fullName, socialAccounts, claimed }
    } = this.props
    const username = fullName.length > 15
      ? insert(14, ' ', fullName).join('')
      : fullName
    const isGreen = match >= 50
    const isClaimed = claimed !== false

    return (
      <li className={cn(c.wrapper, animOut && c.animOut)}>
        {action === 'skip'
          ? <SwipeActionButton type='skip' onClick={this.onActionClick} className={cn(animOut && c.dotOutAnim)} />
          : <div className={c.placeholder} />
        }
        <article
          tabIndex={0}
          onClick={onItemClick}
          onKeyDown={this.onKeyDown}
          className={cn(c.container, animOut && selectCardAnim(action))}
        >
          <h2 className={c.name}>
            <Dotdotdot clamp={1}>{username}</Dotdotdot>
          </h2>

          <MatchAvatar data={{ avatar, match, claimed: isClaimed }} red={!isGreen} />
          {
            isClaimed && (
              <span aria-hidden="true" className={cn(c.match, isGreen ? c.green : c.red)}>{match}% compatible</span>
            )
          }

          <div className={c.buttonsWrapper}>
            {
              isClaimed && (
                <Button className={c.button} onClick={this.onStartChatClick}>
                  <img src={ChatIcon} alt="" aria-hidden="true" />
                  <span className={c.label}>{i18n.t('profile.startChat')}</span>
                </Button>
              )
            }
            <Button className={c.button} onClick={this.onRequestClick}>
              <img src={ArrowIcon} alt="" aria-hidden="true" />
              <span className={c.label}>{i18n.t('profile.request')}</span>
            </Button>
            <Button className={c.button}>
              <img src={PersonIcon} alt="" aria-hidden="true" />
              <span className={c.label}>{i18n.t('profile.viewProfile')}</span>
            </Button>
          </div>

          {
            isClaimed && (
              <SocialSection
                topDivider
                isFocusable
                logType={logType}
                socialAccounts={socialAccounts}
              />
            )
          }
        </article>
        {action === 'like'
          ? <SwipeActionButton type='like' onClick={this.onActionClick} className={cn(animOut && c.dotOutAnim)} />
          : <div className={c.placeholder} />
        }
      </li>
    )
  }
}

SavedCard.propTypes = {
  action: PropTypes.string.isRequired,
  onActionClick: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  data: PropTypes.shape({
    avatar: PropTypes.string,
    match: PropTypes.number,
    fullName: PropTypes.string,
    description: PropTypes.string,
    common: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
}

export { SavedCard }
