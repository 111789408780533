import { PureComponent } from 'react'
import { Field, reduxForm } from 'redux-form'
import { RoundButton, EditIcon, ActivityIndicator } from 'components'
import cn from 'classnames'
import FadeIn from 'react-fade-in'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import contentSelectors from 'redux/selectors/ContentSelectors'
import { contentActions } from 'redux/actions/ContentActions'
import CheckedIcon from 'static/images/checked-symbol.svg'
import AccountCheckIcon from 'static/images/account-check.svg'
import { FILTERS_MAJORS, FILTERS_HOUSING } from 'constants/urls'
import { swipeActions } from 'redux/actions/SwipeActions'
import swipeSelectors from 'redux/selectors/SwipeSelectors'
import communitySelectors from 'redux/selectors/CommunitySelectors'

import AcademicIcon from 'static/images/academicV2.svg'
import HouseIcon from 'static/images/houseV2.svg'
import EnterIcon from 'static/images/login.svg'
import BedIcon from 'static/images/bedWhiteV2.svg'
import UsersIcon from 'static/images/users.svg'

import { SwitchInput } from 'components/SwitchInput'
import i18n from 'services/i18n'

import { GroupSizeSelectInput } from './GroupSizeSelectInput'
import { GenderSelectInput } from './GenderSelectInput'
import s from './PreferencesSearch.module.scss'

const registrationOptions = {
  'All': 'ALL',
  'Registered Only': 'REGISTERED_ONLY',
  'Non-Registered Only': 'NON_REGISTERED_ONLY'
}

const renderOption = ({ id, name }) => (
  <li key={id} className={s.option}>
    <img alt="" src={CheckedIcon} className={s.checkedIcon} aria-hidden="true" />
    {name}
  </li>
)

const renderMajors = ({ data, input: { value } }) =>
  <>
    {value.map(idx => renderOption(data.find(({ id }) => String(id) === String(idx))))}
  </>

const renderHousing = ({ data, input: { value } }) =>
  <>
    {value.map(idx => renderOption(data.find(({ id }) => String(id) === String(idx))))}
  </>

const onClick = (push, path) => () => push(path)

class PreferencesSearchCmp extends PureComponent {
  componentDidMount() {
    const { getVerifyInfoContent, verifyInfoContent } = this.props
    if (!verifyInfoContent.title) {
      getVerifyInfoContent()
    }
  }

  onClear = () => {
    const { change, initialValues, setSearchValues } = this.props
    initialValues.sex && change('sex', 0)
    change('lifestylePreferences', false)
    change('majors', [])
    change('housing', [])
    change('groupSizeFilter', 0)
    change('registrationStatus', 'ALL')

    setSearchValues({ goBackDisabled: true })
  }

  render() {
    const { push, initialValues, handleSubmit, isRequestLoading, maxGroupSize, sexOptions, genderTitle } = this.props

    return (
      <FadeIn>
        <section className={s.container}>
          {!!this.props.isGenderEnabled && initialValues.sex >= 0 && (
            <section className={s.academicMajors} tabIndex={0}>
              <div className={s.iconContainer}>
                <img alt="" src={AccountCheckIcon} className={s.icon} aria-hidden="true" />
              </div>
              <div className={s.info}>
                <div className={s.body}>
                  <h3>{genderTitle || i18n.t('search.gender')}</h3>
                </div>
                <Field
                  name='sex'
                  sexOptions={sexOptions}
                  component={GenderSelectInput}
                />
              </div>
            </section>
          )}
          <section className={s.academicMajors} tabIndex={0}>
            <div className={s.iconContainer}>
              <img alt="" src={EnterIcon} className={s.icon} aria-hidden="true" />
            </div>
            <div className={s.info}>
              <div className={s.body}>
                <h3>{i18n.t('search.registration')}</h3>
              </div>
              <Field
                name='registrationStatus'
                sexOptions={registrationOptions}
                component={GenderSelectInput}
              />
            </div>
          </section>
          {initialValues.majorOptions && (
            <section className={s.academicMajors} tabIndex={0}>
              <div className={s.iconContainer}>
                <img alt="" src={AcademicIcon} className={s.icon} aria-hidden="true" />
              </div>
              <div className={s.info}>
                <div className={s.body}>
                  <h3>{i18n.t('search.majors')}</h3>
                  <ul className={s.options}>
                    {initialValues.majors &&
                      <Field
                        name='majors'
                        component={renderMajors}
                        data={initialValues.majorOptions}
                      />
                    }
                  </ul>
                </div>
                <EditIcon onClick={onClick(push, FILTERS_MAJORS)} label={i18n.t('search.editMajors')} />
              </div>
            </section>
          )}
          {initialValues.housingOptions && (
            <section className={s.housingOptions} tabIndex={0}>
              <div className={s.iconContainer}>
                <img alt="" src={BedIcon} className={s.icon} aria-hidden="true" />
              </div>
              <div className={s.info}>
                <div className={s.body}>
                  <h3>{i18n.t('search.housing')}</h3>
                  <ul className={s.options}>
                    {initialValues.housing &&
                      <Field
                        name='housing'
                        component={renderHousing}
                        data={initialValues.housingOptions}
                      />
                    }
                  </ul>
                </div>
                <EditIcon onClick={onClick(push, FILTERS_HOUSING)} label={i18n.t('search.editHousing')} />
              </div>
            </section>
          )}
          <section className={s.lifestyle} tabIndex={0}>
            <div className={s.iconContainer}>
              <img alt="" src={UsersIcon} className={s.icon} aria-hidden="true" />
            </div>
            <div className={s.info}>
              <div className={s.body}>
                <h3>{i18n.t('search.group')}</h3>
              </div>
              <Field
                name='groupSizeFilter'
                maxGroupSize={maxGroupSize}
                component={GroupSizeSelectInput}
              />
            </div>
          </section>
          <section className={s.lifestyle} tabIndex={0}>
            <div className={s.iconContainer}>
              <img alt="" src={HouseIcon} className={s.icon} aria-hidden="true" />
            </div>
            <div className={s.info}>
              <div className={s.body}>
                <h3>{i18n.t('search.apply')}</h3>
                <p>
                  {i18n.t('search.byApplying')}
                </p>
              </div>
              <Field
                labelOn='ON'
                labelOff='OFF'
                label='Room filter'
                component={SwitchInput}
                name='enableRoomSizeFilter'
              />
            </div>
          </section>
          <footer>
            <RoundButton
              className={s.button}
              onClick={this.onClear}
              disabled={isRequestLoading}
            >
              {i18n.t('search.clear')}
            </RoundButton>
            <RoundButton
              className={cn(s.button, s.white)}
              onClick={handleSubmit}
            >
              {isRequestLoading
                ? <ActivityIndicator blue />
                : i18n.t('search.submit')
              }
            </RoundButton>
          </footer>
        </section>
      </FadeIn>
    )
  }
}

const PreferencesSearchForm = reduxForm({
  form: 'preferencesSearch',
  destroyOnUnmount: false
})(PreferencesSearchCmp)

const mapStateToProps = createStructuredSelector({
  sexOptions: swipeSelectors.sexOptionsSelector,
  maxGroupSize: swipeSelectors.groupMaxSizeSelector,
  initialValues: swipeSelectors.searchValuesSelector,
  genderTitle: communitySelectors.genderTitleSelector,
  isGenderEnabled: contentSelectors.genderEnabledSelector,
  verifyInfoContent: contentSelectors.verifyInfoContentSelector,
  isRequestLoading: swipeSelectors.isSearchRequestLoadingSelector
})

const mapDispatchToProps = {
  push,
  getVerifyInfoContent: contentActions.getVerifyInfoContent,
  onSubmit: swipeActions.setSearchValues,
  setSearchValues: swipeActions.setSearchValues,
}

const PreferencesSearch = connect(mapStateToProps, mapDispatchToProps)(PreferencesSearchForm)

export { PreferencesSearch }
