import { put, call, select, take } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { push, replace } from 'connected-react-router'
import { show } from 'redux-modal'
import equals from 'ramda/es/equals'
import path from 'ramda/es/path'
import pathOr from 'ramda/es/pathOr'
import { ZendeskAPI } from "react-zendesk/src"

import { ToastsStore } from 'react-toasts'

import getRedirectPath from 'services/redirectHelper'
import userSelectors from '../selectors/UserSelectors'
import profileSelectors from '../selectors/ProfileSelectors'
import contentSelectors from '../selectors/ContentSelectors'
import { userActions } from 'redux/actions/UserActions'
import { swipeActions } from 'redux/actions/SwipeActions'
import { contentActions } from 'redux/actions/ContentActions'
import { handleToastFocus } from 'helpers/handleToastFocus'
import { profileActions, profileTypes } from 'redux/actions/ProfileActions'
import { ABOUT_ME, SOCIAL_ACCOUNTS, PROFILE_OVERVIEW, PROFILE_PREVIEW, QUESTIONS, VERIFY_INFO } from '../../constants/urls'
import { getProfanityWords } from 'services/helpers'
import API from 'services/api'
import i18n from 'services/i18n'

import s from '../../layouts/SignedInLayout/Toast.module.scss'

export function* getProfile(action) {
  const { responseSuccess, responseFailure } = action
  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res, code } = yield call(API.getProfile, accessToken, membershipId, membershipId)
  
    if (code === 403 || code === 404) {
      const memberships = yield select(userSelectors.membershipsSelector)
      const newMemberships = memberships.filter(membership => membership.membershipId !== membershipId)

      yield put(
        userActions.updateAuthData({
          memberships: newMemberships
        })
      )
      yield put(show(
        'infoModal',
        {
          memberships: newMemberships,
          label: 'Membership cancelled',
          description: (
            <p>
              Membership is no longer accessible and will be switched.{'\n\n'}If you have questions, please contact <a href='email:support@roomsync.com'>support@roomsync.com</a>
            </p>
          )
        }
      ))
    } else {
      const { majors, housing, ...other } = res

      const isMyUserFetched = yield select(profileSelectors.questions)
      const aboutMeHasAllAnswers = yield select(
        profileSelectors.aboutMeHasAllAnswersSelector,
        other?.lifestyleQuestionsWithAnswers?.questions
      )

      if (
        isMyUserFetched &&
        !aboutMeHasAllAnswers &&
        window?.location?.pathname !== QUESTIONS &&
        window?.location?.pathname !== PROFILE_OVERVIEW &&
        window?.location?.pathname!== PROFILE_PREVIEW &&
        window?.location?.pathname!== VERIFY_INFO &&
        window?.location?.pathname!== ABOUT_ME
      ) {
        yield put(replace(QUESTIONS))
      }

      yield put(responseSuccess({
        majors: majors || null,
        housing: housing || null,
        ...other
      }))
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* getProfileOverview(action) {
  const { responseSuccess, responseFailure } = action
  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)

    if (!membershipId) {
      return
    }

    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res, code } = yield call(API.getProfileOverview, accessToken, membershipId, membershipId)
    if (res) {
      yield put(responseSuccess({
        profile_picture_urls: res.profile_picture_urls,
        sections: res.sections,
        title: res.title,
        networkName: res.network_name,
        subnetworkName: res.subnetwork_name,
        l3ForcedClosed: res.l3ForcedClosed,
        unitType: res.unit_type,
        roomType: res.room_type,
        first_name: res.first_name,
        last_name: res.last_name
      }))

      const community = yield select(profileSelectors.communitySelector)
      ZendeskAPI('messenger', 'updateSettings', {
        contactForm: {
          fields: [
            { id: 360042833871, prefill: { '*': community } }
          ]
        }
      })
    } else if (code === 403 || code === 404) {
      const memberships = yield select(userSelectors.membershipsSelector)
      const newMemberships = memberships.filter(membership => membership.membershipId !== membershipId)

      yield put(
        userActions.updateAuthData({
          memberships: newMemberships
        })
      )
      yield put(show(
        'infoModal',
        {
          memberships: newMemberships,
          label: 'Membership cancelled',
          description: (
            <p>
              Membership is no longer accessible and will be switched.{'\n\n'}If you have questions, please contact <a href='email:support@roomsync.com'>support@roomsync.com</a>
            </p>
          )
        }
      ))
    } else {
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* getMajorsOptions(action) {
  const { responseSuccess, responseFailure } = action
  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res } = yield call(API.getProfileMajors, accessToken, membershipId)

    if (res) {
      yield put(responseSuccess({ majors: res.majors || [] }))
      yield put(contentActions.getMajorsContent())
    } else {
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* getHousingOptions(action) {
  const { responseSuccess, responseFailure } = action
  try {
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const { res } = yield call(API.getHousingOptions, accessToken, membershipId)

    if (res) {
      yield put(responseSuccess({ housing: res.housing || [] }))
      yield put(contentActions.getHousingContent())
    } else {
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* setMajorsOptions(action) {
  const { responseSuccess, responseFailure, data } = action
  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res } = yield call(API.setMajorsOptions, accessToken, membershipId, data)

    if (res) {
      const { majors } = yield select(contentSelectors.majorsContentSelector)
      yield put(swipeActions.getSwipeData({ status: 'queued', page: 0 }))
      yield put(responseSuccess({ majors: data.map(id => majors.find(item => +item.id === id)).filter(v => !!v) }))
      yield put(profileActions.changeOverviewStatus('majors'))
      ToastsStore.success(i18n.t('default.updated'), 3000, s.toast)
      handleToastFocus()
      yield put(push(getRedirectPath(res)))
    } else {
      ToastsStore.error('Update failed!', 3000, s.toast)
      handleToastFocus()
      yield put(responseFailure())
    }
  } catch (err) {
    ToastsStore.error('Update failed!', 3000, s.toast)
    handleToastFocus()
    yield put(responseFailure(err))
  }
}

export function* setHousingOptions(action) {
  const { responseSuccess, responseFailure, data } = action
  try {
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const { res } = yield call(API.setHousingOptions, accessToken, membershipId, data)

    if (res) {
      const { housing } = yield select(contentSelectors.housingContentSelector)
      yield put(swipeActions.getSwipeData({ status: 'queued', page: 0 }))
      yield put(responseSuccess({ housing: data.map(id => housing.find(item => +item.id === id)).filter(v => !!v) }))
      yield put(profileActions.changeOverviewStatus('housing'))
      ToastsStore.success(i18n.t('default.updated'), 3000, s.toast)
      handleToastFocus()
      yield put(push(getRedirectPath(res)))
    } else {
      ToastsStore.error('Update failed!', 3000, s.toast)
      handleToastFocus()
      yield put(responseFailure())
    }
  } catch (err) {
    ToastsStore.error('Update failed!', 3000, s.toast)
    handleToastFocus()
    yield put(responseFailure(err))
  }
}

export function* getSocialAccounts(action) {
  const { responseSuccess, responseFailure } = action
  try {
    const userId = yield select(userSelectors.userIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res } = yield call(API.getSocialAccounts, accessToken, userId)

    yield put(responseSuccess({
      socialAccountsContent: {
        title: res.title,
        heading: res.heading
      },
      social_accounts: res.social_accounts
    }))
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* setSocialAccounts(action) {
  const { responseSuccess, responseFailure, data: { username } } = action
  try {
    const userId = yield select(userSelectors.userIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const socialAccounts = yield select(profileSelectors.socialAccounts)
    const currentPathname = yield select(state => path(['router', 'location', 'pathname'], state))
    const type = currentPathname.split('/')[2]
    const socialAccount = socialAccounts.find(({ social_account_type: sat }) => sat === type)

    const data = [
      {
        ...socialAccount,
        social_account_user_name: username
      }
    ]
    const { res, err } = yield call(API.setSocialAccounts, accessToken, userId, data)

    if (res) {
      yield put(responseSuccess({ social_accounts: res.social_accounts }))
      yield put(profileActions.changeOverviewStatus('social_accounts'))
      yield put(push(SOCIAL_ACCOUNTS))
    } else {
      const { error: { description } } = err

      ToastsStore.error(description, 8000, s.toast)
      handleToastFocus()
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* getAboutMe(action) {
  const { responseSuccess, responseFailure } = action
  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res } = yield call(API.getAboutMe, accessToken, membershipId)

    if (res) {
      yield put(responseSuccess({
        summary: res.about_me,
        questions: res.questions,
        title: res.title
      }))
    } else {
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* setAboutMe(action) {
  const { responseFailure, data: { onError, ...data } } = action

  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res, err } = yield call(API.setAboutMe, accessToken, membershipId, data)

    if (res) {
      yield put(swipeActions.getSwipeData({ status: 'queued', page: 0 }))
      yield put(replace(PROFILE_OVERVIEW))
      yield put(profileActions.getAboutMe())

      ToastsStore.success(i18n.t('default.saved'), 8000, s.toast)
      handleToastFocus()
    } else {
      onError && onError()

      if (pathOr('', ['error', 'code'], err).includes('profane')) {
        const profanityWords = getProfanityWords(err.error.code)
        const errorMessage = `${i18n.t('default.notAllowed')}: "${profanityWords.join(', ')}"`
        ToastsStore.error(errorMessage, 8000, s.toast)
        handleToastFocus()
      } else if (pathOr('', ['error', 'code'], err).includes('answer')) {
        ToastsStore.error(i18n.t('default.needAll'), 8000, s.toast)
        handleToastFocus()
      }
    }

    yield put(responseFailure())
  } catch (err) {
    yield put(responseFailure(err))
  }
}

const sendProfilePicture = (blob, urlToUpload) => new Promise((resolve, reject) => {
  const req = new XMLHttpRequest()

  req.open('PUT', urlToUpload, true)
  req.setRequestHeader('Content-Type', 'binary/octet-stream')
  req.onload = () => {
    resolve()
  }
  req.onerror = () => {
    reject(new TypeError('Network request failed'))
  }
  req.send(blob)
})

export function* uploadProfileImage(action) {
  const { responseSuccess, responseFailure, data } = action
  try {
    const userId = yield select(userSelectors.userIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res } = yield call(API.getUploadProfileImageUrl, accessToken, userId, { fileExtension: 'jpg' })

    if (res) {
      const { url: urlToUpload } = res
      yield call(sendProfilePicture, data.image, urlToUpload)
      const lastProfilePictureUrl = yield select(profileSelectors.profilePictureUrls)
      const delays = [2, 3, 4, 5, 6, 7]
      let timeout = false

      for (const delayTime in delays) {
        yield delay(delayTime * 1000)
        yield put(profileActions.getProfile())
        
        const data = yield take(profileTypes.GET_PROFILE_SUCCESS)
        const nextProfilePictureUrls = path(['data', 'profile_picture_urls'], data)

        if (!equals(lastProfilePictureUrl, nextProfilePictureUrls)) {
          yield put(profileActions.getProfileOverview())
          yield put(responseSuccess())

          timeout = false

          ToastsStore.success(`Image uploaded successfully!`, 4000, s.toast)
          handleToastFocus()
          break
        } else {
          timeout = true
        }
      }

      if (timeout) {
        ToastsStore.success(`New profile image will be available soon!`, 4000, s.toast)
        handleToastFocus()
        yield put(responseFailure())
      }
    } else {
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}
