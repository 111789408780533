import cn from 'classnames'
import PropTypes from 'prop-types'

import LeftRed from 'static/images/arrow-right-red-2.svg'
import LeftDefault from 'static/images/arrow-right.svg'
import RightGreen from 'static/images/arrow-left-green-2.svg'
import RightDefault from 'static/images/arrow-left.svg'
import i18n from 'services/i18n'

import s from './SwipeActionButton.module.scss'

const getLabels = () => ({
  skip: i18n.t('swipes.skip'),
  like: i18n.t('swipes.like'),
  next: i18n.t('swipes.next'),
  back: i18n.t('swipes.back')
})

const SwipeActionButton = ({ type, onClick, className }) => (
  <button
    // tabIndex={-1}
    onClick={onClick}
    className={cn(s.container, className, s[type])}
  >
    {(type === 'skip' || type === 'back')
      ? <img aria-hidden="true" alt="" src={type === 'skip' ? LeftRed : LeftDefault} />
      : <img aria-hidden="true" alt="" src={type === 'like' ? RightGreen : RightDefault} />
    }
    <span aria-hidden="true" className={cn(
      s.label,
      type === 'like' && s.like,
      type === 'skip' && s.skip
    )}>{getLabels()[type]}</span>
  </button>
)

SwipeActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string
}

export { SwipeActionButton }
