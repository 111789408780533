import path from 'ramda/es/path'
import images from 'static'
import { ZendeskAPI } from "react-zendesk/src"

import {
  HOME,
  SWIPE,
  HOUSING,
  QUESTIONS,
  VERIFY_INFO,
  PROFILE_OVERVIEW,
  WELCOME
} from 'constants/urls'
import { EMAIL_BODY, EMAIL_SUBJECT } from 'constants/strings'

export default (res) => {
  const nextLink = path(['_links', 'next', 'href'], res) || res?.next
  if (!nextLink) return HOME

  return (nextLink.includes('overview') && PROFILE_OVERVIEW) ||
  (nextLink.includes('user_info_verification') && VERIFY_INFO) ||
  (nextLink.includes('get_started') && PROFILE_OVERVIEW) ||
  (nextLink.includes('questions') && QUESTIONS) ||
  (nextLink.includes('housing') && HOUSING) ||
  (nextLink.includes('potential_roommates') && res.l3ForcedClosed && WELCOME) ||
  (nextLink.includes('potential_roommates') && SWIPE) || SWIPE
}

export const emailRedirect = (email) => {
  window.location.href = `mailto:${email}?subject=${EMAIL_SUBJECT}&body=${EMAIL_BODY}`
}

export const getProfileImage = (data) => path(['profile_picture_urls', 'large'], data) || images.defaultAvatar
export const getRoommateImage = (data) => path(['image_urls', 'large'], data) || images.defaultAvatar

export const supportRedirect = () => {
  ZendeskAPI('messenger', 'open')
}
