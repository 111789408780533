import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import cn from 'classnames'
import toUpper from 'ramda/es/toUpper'
import { swipeActions } from 'redux/actions/SwipeActions'
import swipeSelectors from 'redux/selectors/SwipeSelectors'
import i18n from 'services/i18n'

import { Button } from 'components/Button'

import s from './SearchValues.module.scss'

const registrationOptions = {
  'ALL': 'All',
  'REGISTERED_ONLY': 'Registered Only',
  'NON_REGISTERED_ONLY': 'Non-Registered Only'
}

class SearchValuesView extends PureComponent {
  state = {
    visible: 0,
    animate: !this.props.isAnySearch
  }

  componentDidMount() {
    const { getSearchValues, isAnySearch } = this.props
    getSearchValues()

    if (isAnySearch) {
      this.container.style.opacity = 1
      this.setState({ visible: this.container.clientHeight, animate: true })
    }
  }

  componentDidUpdate({ isAnySearch: prevIsAnySearch }) {
    if (!prevIsAnySearch && this.props.isAnySearch && this.container) {
      this.setState({ visible: this.container.clientHeight, animate: true })
    }
  }

  renderItem = ({ name }) =>
    <li key={name} className={s.itemWrapper} tabIndex={0}>
      <span>{name}</span>
    </li>

  handleRef = ref => { this.container = ref }

  clearFilters = () => {
    this.props.clearFilters({ goBackDisabled: true })
    this.setState({ visible: 0, animate: true })
  }

  render() {
    const { visible, animate } = this.state
    const { housing, majors, name, lifestyle, sex, enableRoomSizeFilter, groupSize, registrationStatus } = this.props

    return (
      <div
        className={cn(!animate ? null : visible ? s.fadeIn : s.fadeOut)}
        style={{ height: animate ? visible : undefined, opacity: !animate ? 1 : visible > 0 ? 1 : 0 }}
      >
        {this.props.isAnySearch && (
          <section ref={this.handleRef} tabIndex={0} aria-label={i18n.t('search.filters')}>
            <ul className={s.optionsContainer}>
              {groupSize > 0 && this.renderItem({ id: 'groupSize', name: i18n.t('search.groupSize', { groupSize }) })}
              {lifestyle && this.renderItem({ id: 'lifestyle', name: i18n.t('search.lifestyle') })}
              {sex && this.renderItem({ id: 'sex', name: sex.replace(/^./, toUpper) })}
              {housing.map(this.renderItem)}
              {majors.map(this.renderItem)}
              {enableRoomSizeFilter && this.renderItem({ id: 'enableRoomSizeFilter', name: i18n.t('search.room') })}
              {registrationStatus !== 'ALL' && this.renderItem({ id: 'registrationStatus', name: registrationOptions[registrationStatus] })}
            </ul>
            <div className={s.actionsContainer}>
              {name
                ? <span className={s.results}>{i18n.t('search.results', { name })}</span>
                : <div />
              }
              <Button
                className={s.buttonWrapper}
                onClick={this.clearFilters}
              >
                <span>{name ? i18n.t('search.clearSearch') : i18n.t('search.clearFilters')}</span>
              </Button>
            </div>
          </section>
        )}
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  sex: swipeSelectors.sexSelector,
  name: swipeSelectors.searchNameSelector,
  majors: swipeSelectors.searchMajorsSelector,
  lifestyle: swipeSelectors.lifestyleSelector,
  groupSize: swipeSelectors.groupSizeSelector,
  housing: swipeSelectors.searchHousingSelector,
  isAnySearch: swipeSelectors.isAnySearchSelector,
  registrationStatus: swipeSelectors.registrationStatusSelector,
  enableRoomSizeFilter: swipeSelectors.enableRoomSizeFilterSelector
})

const mapDispatchToProps = {
  getSearchValues: swipeActions.getSearchValues,
  clearFilters: swipeActions.setSearchValues
}

const SearchValues = connect(mapStateToProps, mapDispatchToProps)(SearchValuesView)

export { SearchValues }
