import { pipe, defaultTo, map, prop } from 'ramda'
import { createSelector } from 'reselect'

const getState = state => state.user

const authSelector = state => getState(state).auth
const selectedRoommate = state => getState(state).selectedRoommate
const loadingStates = state => getState(state).loadingStates
const registerStates = state => getState(state).register
const accessTokenSelector = state => authSelector(state).accessToken
const userIdSelector = state => authSelector(state).userId
const membershipIdSelector = state => authSelector(state).membershipId// + '1'
const networkIdSelector = state => registerStates(state).networkId
const subnetworkIdSelector = state => registerStates(state).subnetworkId
const unitTypeIdSelector = state => registerStates(state).unitTypeId
const roomTypeIdSelector = state => registerStates(state).roomTypeId
const isAuthenticatedSelector = state => accessTokenSelector(state) && userIdSelector(state)
const membershipsSelector = state => authSelector(state).memberships

const membershipsIdsSelector = createSelector(
  membershipsSelector,
  pipe(
    defaultTo([]),
    map(prop('membershipId'))
  )
)

const registerNetworkLoadingSelector = createSelector(
  loadingStates,
  loading => loading.networkId
)

const registerRoomTypeLoadingSelector = createSelector(
  loadingStates,
  loading => loading.roomTypeId
)

const registerSubnetworkLoadingSelector = createSelector(
  loadingStates,
  loading => loading.subnetworkId
)

const selectedRoommateSelector = createSelector(
  selectedRoommate,
  selectedRoommate => selectedRoommate
)

const registerUnitTypeLoadingSelector = createSelector(
  loadingStates,
  loading => loading.unitTypeId
)

const registerPasscodeLoadingSelector = createSelector(
  loadingStates,
  loading => loading.passcode
)

const registerVerifyInitialValuesSelector = createSelector(
  registerStates,
  ({ firstName, lastName, email, gender, hidden }) => ({
    firstName,
    lastName,
    email,
    gender,
    hidden
  })
)

const isHiddenSelector = createSelector(
  registerStates,
  ({ hidden }) => hidden
)

const registerVerifyInfoLoadingSelector = createSelector(
  loadingStates,
  loading => loading.verifyInfo
)

const zendeskSelector = createSelector(
  registerStates,
  ({ firstName, lastName, email }) => ({
    name: firstName + ' ' + lastName,
    email,
  })
)
const myFullNameSelector = createSelector(
  registerStates,
  ({ firstName, lastName }) => firstName + ' ' + lastName,
)

export default {
  userIdSelector,
  networkIdSelector,
  unitTypeIdSelector,
  subnetworkIdSelector,
  roomTypeIdSelector,
  accessTokenSelector,
  membershipIdSelector,
  isHiddenSelector,
  zendeskSelector,
  membershipsSelector,
  isAuthenticatedSelector,
  myFullNameSelector,
  membershipsIdsSelector,
  selectedRoommateSelector,
  registerNetworkLoadingSelector,
  registerUnitTypeLoadingSelector,
  registerSubnetworkLoadingSelector,
  registerVerifyInfoLoadingSelector,
  registerVerifyInitialValuesSelector,
  registerRoomTypeLoadingSelector,
  registerPasscodeLoadingSelector
}
