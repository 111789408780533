import {
  useState,
  useEffect,
  useMemo,
  useCallback
 } from 'react'
import PropTypes from 'prop-types'

import { MenuItem } from 'components'

import Swipe from 'static/images/swipe.svg'
import SwipeSelected from 'static/images/swipeSelected.svg'
import Group from 'static/images/group.svg'
import GroupSelected from 'static/images/groupSelected.svg'
import Chats from 'static/images/chats.svg'
import ChatsSelected from 'static/images/chatsSelected.svg'
import Home from 'static/images/home.svg'
import HomeSelected from 'static/images/homeSelected.svg'
import Dots from 'static/images/dots.svg'
import DotsSelected from 'static/images/dotsSelected.svg'
import Email from 'static/images/mail.svg'
import Arrow from 'static/images/arrow.svg'
import Logo from 'static/images/logoMenu.svg'

import { MAIN_NAV_ID } from 'constants/strings'

import s from './SideMenu.module.scss'

const items = {
  register: [
    { type: 'arrow', icon: Arrow },
    { type: 'email', icon: Email }
  ],
  default: [
    { type: 'group', icon: Home, iconSelected: HomeSelected },
    { type: 'chats', icon: Chats, iconSelected: ChatsSelected },
    { type: 'swipe', icon: Swipe, iconSelected: SwipeSelected },
    { type: 'home', icon: Group, iconSelected: GroupSelected },
    { type: 'dots', icon: Dots, iconSelected: DotsSelected },
    { type: 'email', icon: Email }
  ],
  communityClosed: [
    { type: 'group', icon: Home, iconSelected: HomeSelected },
    { type: 'chats', icon: Chats, iconSelected: ChatsSelected },
    { type: 'welcome', icon: Logo },
    { type: 'dots', icon: Dots, iconSelected: DotsSelected },
    { type: 'email', icon: Email },
  ],
}

const SideMenu = ({ type, unreadMessagesCount, incomingRequests, initialSelection, onItemClick }) => {
  const [selected, setSelected] = useState(initialSelection || 0);

  useEffect(() => {
    setSelected(initialSelection || 0)
  }, [initialSelection])

  const handleItemClick = useCallback((index) => {
    if (selected !== index) {
      type !== 'register' && setSelected({ selected: index })
    }
    onItemClick && onItemClick(index)
  },[selected, onItemClick, type])

  
  const badges = useMemo(() => ({
    chats: unreadMessagesCount,
    home: incomingRequests
  }), [unreadMessagesCount, incomingRequests])

  return (
    <nav className={s.wrapper} id={MAIN_NAV_ID} tabIndex={-1}>
      <ul className={s.container}>
        {items[type].map(({ type, icon, iconSelected }, idx) =>
          <MenuItem
            key={type}
            index={idx}
            type={type}
            size='small'
            Icon={selected === idx ? iconSelected || icon : icon}
            selected={selected === idx}
            onClick={handleItemClick}
            badge={badges[type]}
            sideMenu={true}
          />
        )}
      </ul>
      {type !== 'register' && selected >= 0 &&
        <div className={s.indicatorWrapper} style={{ marginTop: selected * 64 || 0 }}>
          <div />
        </div>
      }
    </nav>
  )
}


SideMenu.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  type: PropTypes.string
}

SideMenu.defaultProps = {
  type: 'default'
}

export { SideMenu }
