// temporary resources as png, not svg unti we receive better assets

const defaultImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARkAAAC0CAMAAACXO6ihAAAAYFBMVEXR1dr////N0' +
'9fP0tf///3S1Nr//v/T1NvO1Nj7+/z39/jN0dfQ0dfa297s7fDW2Nzj5+nq7e7q6uvy8vPg4uTw9PXW19jo6Oje3+PU2t3f' +
'5ebZ3+Hp6Ozr6u7o7vH5/v6jK179AAAFvUlEQVR4nO2d7ZqqOgyFoSXyIQoIzsbRs73/uzzozOxhVEbbBgIL3v8+lvWkaZOm' +
'qectLCwsLCwsLCwsLCwsTAndID2GERGGnqeUzuoq3xXbXV5tPCU9pnFAlFVF4rfZ54vpaF0V8UWMIAja2sTVnO0mDPVx73fxd' +
'y09PjH02y7u1MUP/HKeVkP68Ldblg/KOVoN0faZLo3VvM/PD6vjL/OoJU1G0iMdGF28oMt1PqXzkkaXfvBclSubLPNUJD3goVDJc' +
'0Xa7HNvHv5G74NXLcb/2gCW0oMegmYqWXA+wpuNym2EafwSeCRFtLES5sJ/qfTo+4TSV/YxHVZzQraa9GRtMo0j3kgPv0eylbUyDTH' +
'uxkY/D5Z+tZoCdj6RizAXslD6E/ohcjKZC1vQ9UlZL0z/APU0R2dhQPd7yiou+EkJqczaacn+BFIZ+8CgBWSWz37/26ICVIbDzTTrN' +
'uB0UhzCQLrgA4syCaAytc+xNsV4yugTjzJ455ZNnG2QGO8GLz7QpcmRQTfS38GPNjxl6uIg/SHsaPdAe1FmZsp4PMIsyizKLMqwKYO3n' +
'2FSZiX9GfxoJmUA4yaeVRsxouTZAy/KdAGYn2GKKAFzekxZCMA8sLYrQ7tlB3h28M6iTA5YDcGTIa8BbSZiUQawIk3ZFy+2Aay1LxIWZfa' +
'F9Icwo5OAJ0He7IKlv4UXljqIT3Kk+cS0mflgD3S/klLn2sUWMZIyL9yafJ0zkjKsNnMG8jO8NoM0my6FEIsyD9E1ozIJkjJexqgM0qrdwFM' +
'8c2Ur/S2s6JhPmVz6Y1hR3d1UjKmBVm3PixjDA7DcFd/ihHausmYqLPL9E9bS5HiFsk0m/Snc8CTI4XYznm0/iHsOUCvTFZ6zA8TrguR2jf2DP' +
'4DCNNDWUZv9BlMY5wQWVPrhhtopsERuPZg5KXOSHn6PuIXcgGfa/3ALuTVghcgXTiF3gqyMU9ZzB7pkf+BSFVxLD75XXHY0eAFTG6qshcELsn9A' +
'9hczsBLj96innaS7AEv/3mObwQIskb7B1gdLj7t/wjcrYbC7dn5ytAgr4xDezTQoi/m0QQ4MvtF7U6tBzP4+JDKMEbZ4rVU6iU2sZj8H7/sFJa8' +
'rg1Vj9QwyqAAAD5huMElhzctmolffD/GxD1PuUQbK+PNSxiRVjp2yusFIGenBDoqRMnC1RL9hlClHPoG7IzWpisA+NLjBqL8/egb4B0b3KrGP4G4' +
'wKg8G7B/SjdGxE3LdzC0vPy54BbDzbRekzapFvDkkga+Ehgf/M8kCe5cbp0bCQLbyf0xkeO4/HxdsXJQ2m2jb+JgStHT8jrX55QzpIQ/D2uJ+3Dw8' +
'jVWdSI4vTXQ4W1RLB/jS6M3ZxmQacJ9cvJKebG8eBH6C+8w2pZlTw9dVBfp4vc62vkuDtOa3CeDVrzCMco63eMosxSq/0jrnuspeZjB2Q0SWL7E/oj' +
'G8wlMEkZcgnduu1F0UBFBsFKmKrSVEi5038Z0fpRW3vXxyLpppKv159uia6T2rR6xOE936EekjY6eiR8R5NMUlPN30aC/f2qRTsxt1YOod8lSb90m5' +
'Yp0NpMtVm3oy2qjM6AjSnaSeRHdy7Q2sy4UJdBlZs7aPNmDs4ZTuZcP7GsV4ZxTpIRbqXxjpZR+iIRekx8SbdIRBeONguJ40sKX5/zIb26ZYHRJfXJ' +
'lr9n1kOz+1E9akxXjOGCjUb7Ke9weBv6p0OA53k9q3weiJcgypGyI9opn0RfImr01I4mv1Q+Rr+2hELqZNIBxJhelIhblII5rvS3vOZzohuOmjdITO' +
'95tYi0lDnE959cBWqnYiYnoZuj9qIS9s37NqIFZib073dPzIxkomY0M0avf7QSzR65OIo1KobyoBZUyvVcgg0W6D83mdHhleGPNrFTL8GVwYPYnJ5P' +
'vl4JcwzS6JCjJ0iGB6FVKOoRM1xPlaYK/sho6dOF+Y7BU7R/M/ul5SF38+YlEAAAAASUVORK5CYII='

export default {
  arrowRightBlue: require('./images/right-arrow.png').default,
  arrowRightWhite: require('./images/right-arrow-white.png').default,
  backgroundSquares: require('./images/background-squares.png').default,
  bubble: require('./images/bubble.png').default,
  logo: require('./images/logo.png').default,
  registerPlaceholder: require('./images/register-background.png').default,
  defaultAvatar: defaultImg,
  communityBackground: require('./images/community-header-bg.png').default,
  demoLeft: require('./images/demo_card_left.png').default,
  demoRight: require('./images/demo_card_right.png').default,
  bannerLogo: require('./images/banner-logo.jpg').default,
  googleButton: require('./images/google-button.png').default,
  facebokButton: require('./images/facebook-button.png').default
}
