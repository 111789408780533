import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { show } from 'redux-modal'
import { connect } from 'react-redux'
import cn from 'classnames'

import c from './MatchAvatar.module.scss'

const MatchAvatar = ({ data: { avatar, match, claimed }, size, white, red, showModal }) => {
  const onNotClaimedClick = useCallback((e) => {
    e.stopPropagation()

    showModal('infoModal', {
      label: 'Profile Not Created',
      description: 'This resident has not yet logged into RoomSync to create their matching profile. You can still request them to be your roommate and they will receive an email notification.'
    })
  }, [claimed])

  return (
    <div className={cn(c.container, size === 'small' && c.small)}>
      <img
        alt=""
        src={avatar}
        rel='noreferrer'
        aria-hidden="true"
        className={cn(c.avatar, size === 'small' && c.small)}
      />
      {
        claimed !== false
          ? (
            <div className={cn(c.circleWrapper, size === 'small' && c.small)} aria-label={`${match}% compatible`}>
              <svg
                viewBox='0 0 36 36'
                className={cn(c.circularChart, size === 'small' && c.small)}
              >
                <path
                  className={cn(
                    c.circle,
                    c.gray,
                    size === 'small' && c.small
                  )}
                  strokeDasharray="100, 100"
                  d='M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831'
                />
                {match > 0 && (
                  <path
                    className={cn(
                      c.circle,
                      white && c.white,
                      red && c.red,
                      size === 'small' && c.small
                    )}
                    strokeDasharray={`${match}, 100`}
                    d='M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831'
                  />
                )}
              </svg>
            </div>
          )
          : (
            <div className={cn(c.notClaimedContainer, size === 'small' && c.small)}>
              <div className={c.notClaimedWrapper} onClick={onNotClaimedClick}>
                <span>Profile Not Created</span>
              </div>
            </div>
          )
      }
    </div>
  )
}

MatchAvatar.propTypes = {
  data: PropTypes.shape({
    match: PropTypes.number,
    avatar: PropTypes.string
  }).isRequired,
  size: PropTypes.string,
  white: PropTypes.bool
}

MatchAvatar.defaultProps = {
  size: 'large',
  white: false
}

const mapDispatchToProps = {
  showModal: show
}

const ConnectedMatchAvatar = connect(null, mapDispatchToProps)(MatchAvatar)

export { ConnectedMatchAvatar as MatchAvatar }
