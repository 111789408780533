import { createSelector } from 'reselect'
import path from 'ramda/es/path'
import pathOr from 'ramda/es/pathOr'
import pipe from 'ramda/es/pipe'
import join from 'ramda/es/join'
import trim from 'ramda/es/trim'
import propOr from 'ramda/es/propOr'
import prop from 'ramda/es/prop'
import sortBy from 'ramda/es/sortBy'
import findIndex from 'ramda/es/findIndex'
import is from 'ramda/es/is'
import identity from 'ramda/es/identity'
import images from 'static'

const getState = state => state.profile

const email = state => getState(state).email
const majors = state => getState(state).majors
const housing = state => getState(state).housing
const aboutMe = state => getState(state).aboutMe
const renewal = state => getState(state).renewal
const userQbIdSelector = state => getState(state).userQbId
const socialAccounts = state => getState(state).social_accounts
const socialAccountsContent = state => getState(state).socialAccountsContent || {}
const sectionsInCommon = state => getState(state).sectionsInCommon
const mutualFriendsCount = state => getState(state).mutual_friends_count
const mutualFriends = state => getState(state).mutual_friends
const mutualLikesCount = state => getState(state).mutual_likes_count
const mutualLikes = state => getState(state).mutual_likes
const majorsInCommon = state => getState(state).majorsInCommon
const housingInCommon = state => getState(state).housingInCommon
const percentMatch = state => getState(state).percent_match
const chatId = state => getState(state).chat_id
const status = state => getState(state).status
const questions = state => getState(state)?.lifestyleQuestionsWithAnswers?.questions
const myRoommates = state => getState(state).roommates
const loadingStates = state => getState(state).loadingStates
const housingSelector = state => (getState(state).housing || []).map(({ id }) => +id)
const majorsSelector = state => (getState(state).majors || []).map(({ id }) => +id)
export const overviewDataSelector = state => getState(state).overview
const profilePictureUrls = state => overviewDataSelector(state).profile_picture_urls || { large: images.defaultAvatar }

export const profilePageTitleSelector = createSelector(
  overviewDataSelector,
  propOr('Profile', 'title')
)

const firstNameSelector = createSelector(
  overviewDataSelector,
  propOr('', 'first_name')
)

const lastNameSelector = createSelector(
  overviewDataSelector,
  propOr('', 'last_name')
)

const nameSelector = createSelector(
  firstNameSelector,
  lastNameSelector,
  (fName, lName) => pipe(
    join(' '),
    trim
  )([fName, lName])
)

const isProfileLoadingSelector = createSelector(
  loadingStates,
  loading => loading.profile
)

const overviewSectionsSelector = createSelector(
  overviewDataSelector,
  overview => overview ? overview.sections : []
)

const isProfileOverviewLoadingSelector = createSelector(
  loadingStates,
  ({ overview, profile }) => overview || profile
)

const myAvatarSelector = createSelector(
  overviewDataSelector,
  path(['profile_picture_urls', 'large'])
)

const myAvatarSmallSelector = createSelector(
  overviewDataSelector,
  path(['profile_picture_urls', 'square'])
)

const isHousingLoadingSelector = createSelector(
  loadingStates,
  loading => loading.housing
)

const isSocialAccountsLoadingSelector = createSelector(
  loadingStates,
  loading => loading.socialAccounts
)

const isMajorsLoadingSelector = createSelector(
  loadingStates,
  loading => loading.majors
)

const isAboutMeLoadingSelector = createSelector(
  loadingStates,
  loading => loading.aboutMe
)

const isHousingSelector = createSelector(
  isProfileLoadingSelector,
  housing,
  (isProfileLoading, housing) => isProfileLoading && housing
)

const isMajorsSelector = createSelector(
  isProfileLoadingSelector,
  majors,
  (isProfileLoading, majors) => isProfileLoading && majors
)

const socialTypeSelector = (_, props) => path(['match', 'params', 'type'], props)

const socialAccountInitialValuesSelector = createSelector(
  socialAccounts,
  socialTypeSelector,
  (accounts, type) => {
    const account = accounts.find((account) => account.social_account_type === type)
    return { username: account ? account.social_account_user_name : null }
  }
)

const socialAccountSelector = createSelector(
  socialAccounts,
  socialTypeSelector,
  (accounts, type) => accounts.find((account) => account.social_account_type === type) || {}
)

const aboutMeInitialValuesSelector = createSelector(
  getState,
  ({ lifestyleQuestionsWithAnswers: LQWA }) => ({
    ...pathOr([], ['questions', 'SLIDER'], LQWA).reduce((pv, item) => ({
      ...pv,
      [item.questionId]: {
        user: item.userAnswers || 1,
        roommate: [item.roommateLowAnswers || 1, item.roommateHighAnswers || item.numberOfSliderOptions]
      }
    }), {}),
    ...pathOr([], ['questions', 'SELECT'], LQWA).reduce((pv, item) => ({
      ...pv,
      [item.questionId]: {
        user: item.userAnswers,
        roommate: item.roommateAnswers
      }
    }), {}),
    ...pathOr([], ['questions', 'FREE_TEXT'], LQWA).reduce((pv, item) => ({
      ...pv,
      [item.questionId]: item.userAnswer || ''
    }), {})
  })
)

const aboutMeQuestionsSelector = state => getState(state).lifestyleQuestionsWithAnswers

const isUploadProfileImageLoadingSelector = createSelector(
  loadingStates,
  loading => loading.uploadProfile
)

const communitySelector = createSelector(
  overviewDataSelector,
  ({ unitType, roomType, networkName, subnetworkName }) =>
    [networkName, subnetworkName, unitType, roomType]
      .filter(identity)
      .join(' > ')
)

const networkNameSelector = createSelector(
  overviewDataSelector,
  ({ networkName }) => networkName
)


const isNetworkPausedSelector = createSelector(
  overviewDataSelector,
  ({ l3ForcedClosed }) => l3ForcedClosed
)

const aboutMeHasAllAnswersSelector = createSelector(
  (_, questionsFromProps) => questionsFromProps,
  questions,
  (questions, questionsFromProps) => {
    const { SLIDER = [], SELECT = [], FREE_TEXT = [] } = questions || questionsFromProps || {}
    const parsedSlider = sortBy(prop('questionOrder'), SLIDER).map(q => ({
      myAnswer: q.userAnswers,
      roommateLowAnswer: q.roommateLowAnswers,
      roommateHighAnswer: q.roommateHighAnswers,
    }))
    
    const parsedSelect = sortBy(prop('questionOrder'), SELECT).map(q => ({
      myAnswers: is(Array, q?.userAnswers) ? q?.userAnswers : [q?.userAnswers],
      roommateAnswers: is(Array, q?.roommateAnswers) ? q?.roommateAnswers : [q?.roommateAnswers],
    }))
    const parsedText = sortBy(prop('questionOrder'), FREE_TEXT).map(q => q.userAnswer)

    const needResponseForSlider = findIndex(
      ({ myAnswer, roommateLowAnswer, roommateHighAnswer }) => !myAnswer || !roommateLowAnswer || !roommateHighAnswer,
      parsedSlider
    )
  
    if (needResponseForSlider >= 0) {
      return false
    }

    const needResponseForSelect = findIndex(
      ({ myAnswers, roommateAnswers }) => !myAnswers.length || !roommateAnswers.length,
      parsedSelect
    )

    if (needResponseForSelect >= 0) {
      return false
    }

    const needResponseForText = findIndex(
      (myAnswer) => !myAnswer,
      parsedText
    )

    if (needResponseForText >= 0) {
      return false
    }

    return true
  }
)

export default {
  renewal,
  name: nameSelector,
  email,
  chatId,
  status,
  majors,
  housing,
  aboutMe,
  questions,
  mutualLikes,
  myRoommates,
  percentMatch,
  mutualFriends,
  majorsSelector,
  majorsInCommon,
  socialAccounts,
  housingInCommon,
  housingSelector,
  communitySelector,
  networkNameSelector,
  isMyUserFetched: firstNameSelector,
  myAvatarSelector,
  userQbIdSelector,
  sectionsInCommon,
  mutualLikesCount,
  isMajorsSelector,
  lastNameSelector,
  firstNameSelector,
  isHousingSelector,
  mutualFriendsCount,
  profilePictureUrls,
  overviewDataSelector,
  socialAccountSelector,
  socialAccountsContent,
  myAvatarSmallSelector,
  isMajorsLoadingSelector,
  isProfileLoadingSelector,
  aboutMeQuestionsSelector,
  isHousingLoadingSelector,
  isAboutMeLoadingSelector,
  overviewSectionsSelector,
  aboutMeHasAllAnswersSelector,
  aboutMeInitialValuesSelector,
  isSocialAccountsLoadingSelector,
  isProfileOverviewLoadingSelector,
  socialAccountInitialValuesSelector,
  isUploadProfileImageLoadingSelector,
  isNetworkPausedSelector,
}
