import React, { Component } from 'react'
import cn from 'classnames'
import Hammer from 'hammerjs'
import ReactDOM from 'react-dom'
import SimpleCard from './SimpleCard'
import { translate3d } from './utils'
import s from './styles.module.scss'
import { ENTER_KEY_CODE, LEFT_ARROW_KEY_CODE, RIGHT_ARROW_KEY_CODE } from 'constants/common'

const animations = {
  skip: s.swipeOutLeft,
  like: s.swipeOutRight
}

class DraggableCard extends Component {
  state = {
    x: 0,
    y: 0,
    initialPosition: { x: 0, y: 0 },
    startPosition: { x: 0, y: 0 },
    pristine: true,
    swipeOut: null
  }
  timeout = null
  isMoving = false

  resetPosition = () => {
    const { x, y } = this.props.containerSize
    const card = ReactDOM.findDOMNode(this)

    const initialPosition = {
      x: Math.round((x - card.offsetWidth) / 2),
      y: Math.round((y - card.offsetHeight) / 2)
    }

    this.setState(state => ({
      ...state,
      x: initialPosition.x,
      y: 2,
      initialPosition: initialPosition,
      startPosition: { x: 0, y: 0 }
    }))
  }

  panstart = () => {
    const { x, y } = this.state
    this.setState(state => ({
      ...state,
      startPosition: { x, y },
      pristine: false
    }))
  }

  getDirection = () => {
    const { containerSize: screen } = this.props
    const card = ReactDOM.findDOMNode(this)
    const { x, y } = this.state

    switch (true) {
      case (x < -50): return 'Left'
      case (x + (card.offsetWidth - 50) > screen.x): return 'Right'
      case (y < -50): return 'Top'
      case (y + (card.offsetHeight - 50) > screen.y): return 'Bottom'
      default: return false
    }
  }

  panend = (ev) => {
    const direction = this.getDirection()
    ev.preventDefault()
    setTimeout(() => { this.isMoving = false }, 0)
    if (this.props[`onSwipe${direction}`]) {
      this.props[`onSwipe${direction}`]()
      this.props[`onOutScreen${direction}`]()
    } else {
      this.resetPosition()
      this.setState(state => ({ ...state, animation: true }))
    }
  }

  panmove(ev) {
    this.isMoving = true
    this.setState({
      ...this.calculatePosition(ev.deltaX, ev.deltaY),
      animation: false
    })
  }

  handlePan = (ev) => {
    ev.preventDefault()
    this[ev.type](ev)
    return false
  }

  calculatePosition(deltaX, deltaY) {
    const { initialPosition: { x, y } } = this.state
    return {
      x: (x + deltaX),
      y: (y + deltaY)
    }
  }

  animate = (type) => {
    if (type !== this.state.type) {
      this.setState({ swipeOut: type, x: type === 'skip' ? -300 : 300 })
      this.timeout = setTimeout(this.props[`onOutScreen${type === 'skip' ? 'Left' : 'Right'}`], 300)
    }
  }

  componentDidMount() {
    const { onNewCardAppear, item } = this.props
    onNewCardAppear(item, this.animate)
    this.hammer = new Hammer.Manager(ReactDOM.findDOMNode(this))
    this.hammer.add(new Hammer.Pan({ threshold: 2 }))
    this.hammer.on('panstart panend panmove', this.handlePan)

    this.resetPosition()
    window.addEventListener('resize', this.resetPosition)
  }

  componentWillUnmount() {
    if (this.hammer) {
      this.hammer.stop()
      this.hammer.destroy()
      this.hammer = null
    }
    window.removeEventListener('resize', this.resetPosition)
    this.timeout && clearTimeout(this.timeout)
  }

  onClick = () => this.props.onClick(this.isMoving)

  onKeyDown = (event) => {
    switch (event.keyCode) {
      case LEFT_ARROW_KEY_CODE:
        if (this.props.onSwipeLeft) {
          this.props.onSwipeLeft();
        }
        break;
      case RIGHT_ARROW_KEY_CODE:
        if (this.props.onSwipeRight) {
          this.props.onSwipeRight();
        }
        break;
      case ENTER_KEY_CODE:
        if (event.target.tagName === "ARTICLE") {
          this.onClick()
        }
        break;
      default:
        break
    }
  }

  render() {
    const { x, y, swipeOut, animation } = this.state
    const [style, likeStyle, skipStyle] = translate3d(x, y)

    return <SimpleCard
      {...this.props}
      style={style}
      likeStyle={likeStyle}
      skipStyle={skipStyle}
      onClick={this.onClick}
      onKeyDown={this.onKeyDown}
      className={cn(this.props.className, swipeOut && animations[swipeOut], animation && s.onCardBack)}
    />
  }
}

export default DraggableCard
