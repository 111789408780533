import React from 'react';
import { ZendeskAPI } from "react-zendesk/src"

import { Link } from 'components/Link';
import { SideMenu } from 'components/SideMenu'
import { supportRedirect } from 'services/redirectHelper'
import { COMMUNITY_INFO, MORE, ROOM_INCOMING_REQUETS, SWIPE, CHATS, HOME, WELCOME } from 'constants/urls'
import i18n from 'services/i18n'

import s from './navigationSideMenu.module.scss'

import images from 'static'

const menuRouters = [COMMUNITY_INFO, CHATS, SWIPE, ROOM_INCOMING_REQUETS, MORE, WELCOME]
const INIT_SELECTION_CLOSED_PAUSED_IDX = 2
const NO_SELECTION_IDX = -1;

export const NavigationSideMenu: React.FC<any> = props => {
  const networkClosed = props.isError && props.isError.code === 'network_closed'
  const incomingRequests = (networkClosed || props.isNetworkPaused) ? null : props.incomingRequests;
  const type = props.isRegistrationFlow ? 'register' : (props.isNetworkClosed || props.isNetworkPaused) ? 'communityClosed' : 'default'
  const initialSelection = props.isRegistrationFlow ? NO_SELECTION_IDX : (props.isNetworkClosed || props.isNetworkPaused)? INIT_SELECTION_CLOSED_PAUSED_IDX : props.initialSelection
  const logoutUser = React.useCallback(() => {
    ZendeskAPI('messenger', 'reset')
    ZendeskAPI('messenger', 'prefill', {
      name: {
        value: '',
        readOnly: false // optional
      },
      'key:360042833871': {
        value: '',
        readOnly: false // optional
      },
      email: {
        value: '',
        readOnly: false // optional
      },
    })
    props.logoutChat()
    props.resetStore()
    props.history.push(HOME)
  }, [props])

  const onMenuItemClick = React.useCallback((index) => {
    if (props.isRegistrationFlow) {
      if (index === 0) {
        return props.showModal('dialogModal', {
          title: i18n.t('more.logoutTitle'),
          confirmOption: i18n.t('more.yes'),
          cancelOption: i18n.t('more.no'),
          onConfirm: logoutUser
        })
      }

      return supportRedirect()
    } else if (props.isNetworkClosed || props.isNetworkPaused) {
      props.getProfile()

      switch (index) {
        case 0:
          return props.history.push(menuRouters[0])
        case 1:
          return props.history.push(menuRouters[1])
        case 2:
          return props.history.push(menuRouters[5])
        case 3:
          return props.history.push(menuRouters[4])
        default:
          return supportRedirect()
      }
    } else {
      if (index === 5) {
        return supportRedirect()
      }

      props.getMyRoom()
      props.getProfileOverview()

      return props.history.push(menuRouters[index])
    }
  }, [props, logoutUser])

  return props.isAuthenticated && (
    <div className={s.menuWrapper}>
      {type === 'register'
        ?
        <div className={s.logoWrapper}>
          <img src={images.logo} className={s.logo} alt="Roomsync logo" aria-hidden="true"/>
        </div>
        :
        <Link to={HOME} aria-label="Home page" className={s.logoWrapper}>
          <img
            tabIndex={-1}
            src={images.logo}
            className={s.logo}
            alt="Roomsync logo"
            aria-hidden="true"
          />
        </Link>
        }
      
      <SideMenu
        type={type}
        onItemClick={onMenuItemClick}
        incomingRequests={incomingRequests}
        initialSelection={initialSelection}
        unreadMessagesCount={props.unreadMessagesCount}
      />
    </div>
  );
};
